import React, { memo } from 'react'
import PropTypes from 'prop-types'

import TableBodyStyled from './styled'

export const TableBody = props => {
  return <TableBodyStyled className={props.className}>{props.children}</TableBodyStyled>
}

TableBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default memo(TableBody)
